@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Playfair+Display:wght@400;700&family=Poppins:wght@400;700&family=Raleway:wght@400;700&family=Roboto:wght@400;700&family=Urbanist:wght@400;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
:root {
    --color-background: black;
    --stroke-width: calc(1em / 16);
    --font-size: 30vmin;
    --font-weight: 700;
    --letter-spacing: calc(1em / 8);
  }
  body {
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
  overflow-x: hidden;
   
  }
  .hero-img {
    background-image: url('./assets//darkset.png');
    background-size: cover;
    animation-name: moveHead;
    animation-duration: 25s; /* Tiden för övergången */
    animation-timing-function: linear; /* Jämn rörelse */
    animation-iteration-count: infinite; /* Oändlig upprepning */
  
    /* Övriga CSS-regler för din hero-komponent */
  }
  
  @keyframes moveHead {
    0% {
      transform: scale(1); /* Ursprunglig storlek */
    }
    25% {
      transform: scale(1.1); /* Något större (ansiktet närmar sig) */
    }
    50% {
      transform: scale(1); /* Återgå till ursprunglig storlek */
    }
    75% {
      transform: scale(1.1); /* Något mindre (ansiktet avlägsnar sig) */
    }
    100% {
      transform: scale(1); /* Återgå till ursprunglig storlek */
    }
  }
  
  
.hero-container{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}
.hero-img{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    background-color: black;
    opacity: 0.3;

}
.nav{
    top: 1%;
    left: 2%;
    position: absolute;
    z-index: 1000;
    color: white;
   
}

.hero-text{
position: absolute;
top: 23%;
left: 15%;
font-size: 2rem;

}
p{
    font-family: 'Poppins', sans-serif;
}
.hero-text h1{
    background: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%);
    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    line-height: 50px;
}
.hero-text p{
  max-width: 280px;
  color: white;
 letter-spacing: 1px;
font-size: 1.3rem;
font-family: 'roboto', serif;
line-height: 30px;
}
.logo{
    width: 50px;
    height: 50px;
}

.features {
    background: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%);
    color: #fff; /* Vit textfärg */
    padding: 50px 0;
    text-align: center;
    margin-bottom: 2rem;
  }
  .secure-image {
    margin: 1rem 0;
    height: auto;
    padding: 1rem;
    animation: bounceUpDown 2s infinite;
  }
  @keyframes bounceUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px); /* Lyfter bilden upp 10px */
    }
  }
  
  .features h2 {
    max-width: 320px;
    font-family: 'Raleway', sans-serif;
    font-size: 32px;
  margin: 3rem auto;
    color: #fff; /* Vit textfärg */
    font-weight: bold;
  }

  .features p{
    font-size: 1.3rem;
    max-width: 280px;
    margin: 1rem auto;
    line-height: 24px;
  }

.feature img{
  width: 50px !important;
  height: 50px !important;
  margin-bottom: 1rem;
}
  @media (min-width: 768px) {
    .hero-text h1{
 font-size: 5rem;
 line-height: 5rem;
    }
    .hero-text h2{
      font-size: 4rem;
    }
    .hero-text p{
      width: 400px;
    }
 .hero-container{
  height: 100%;
 }
  }
  
  .feature {
    margin: 20px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.7); /* Svart med 70% genomsynlighet */
    border-radius: 10px;
    text-align: center;
    transition: transform 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .feature:hover {
    transform: scale(1.05); /* Lätt förstoring vid hover-effekt */
  }
  
  .feature svg {
    font-size: 40px;
    color: #007bff; /* Blå färg för ikoner */
    margin-bottom: 10px;
  }
  
  .feature h3 {
    font-size: 24px;
    color: #fff; /* Vit textfärg */
    margin-bottom: 10px;
  }
  
  .feature p {
    font-size: 16px;
    color: #ccc; /* Ljusgrå textfärg */

  }
  .index-stats.IndexStats_index-stats__iS8zg {
    background-color: #000; /* Svart bakgrund */
    padding: 50px 0;
    text-align: center;
    color: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%); /* Gradientfärg för text */
  }
  
  .index-stats.IndexStats_index-stats__iS8zg h2 {
    font-size: 28px;
    color: #00a3e0; /* Blå färg för titeln */
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .index-stats.IndexStats_index-stats__iS8zg .index-stats-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .index-stats.IndexStats_index-stats__iS8zg .index-stat {
    flex: 0 1 calc(33.333% - 20px);
    background-color: #1a1f28; /* Mörkare blågrå bakgrund */
    border: 1px solid #0f1117; /* Mörkare kantlinjefärg */
    padding: 20px;
    border-radius: 8px;
    margin: 10px;
    transition: transform 0.2s ease-in-out;
  }
  
  .index-stats.IndexStats_index-stats__iS8zg .index-stat:hover {
    transform: scale(1.05);
  }
  
  .index-stats.IndexStats_index-stats__iS8zg .index-stat-title {
    font-size: 20px;
    color: #00a3e0; /* Blå färg för titeln */
    margin-bottom: 10px;
  }
  
  .index-stats.IndexStats_index-stats__iS8zg .index-stat-number {
    font-size: 24px;
    color: #fff; /* Vit textfärg för numret */
  }
  .about-us {
    background-image: url('./assets//background.png');
    background-size: cover;
    background-attachment: fixed; /* Håller bakgrunden på plats när sidan scrollas */
  
    animation-name: movePlanet;
    animation-duration: 10s; /* Tiden för övergången */
    animation-timing-function: linear; /* Jämn rörelse */
    animation-iteration-count: infinite; /* Oändlig upprepning */
  
    /* Övriga CSS-regler för din "about us"-komponent */
  }
  
  @keyframes movePlanet {
    0% {
      background-position: 100% 50%; /* Ursprunglig position (planet till höger) */
    }
    100% {
      background-position: 0% 50%; /* Slutposition (planet till vänster) */
    }
  }
  
  .about-us {
    background:linear-gradient(rgb(0.5,0.5,0.5,0.5),rgba(0.5,0.5,0.5,0.5)), url('./assets/background.png') no-repeat; /* Ange URL till din bakgrundsbild */
    background-size: cover;
    color: #fff; /* Vit textfärg */
    padding: 80px 0;
    text-align: center;
  }
  
  .about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;
    margin: 0 0.7rem;
  }
  
  .about-text h2 {
    font-size: 28px;
    color: #00a3e0; /* Blå färg för rubrik */
    font-weight: bold;
    margin-bottom: 20px;
   
  }
  
  .about-text p {
    margin: 0 0.7rem;
    text-align: center;
    font-size: 18px;
    line-height: 1.5;
    color: #fff; /* Vit textfärg */
  }
  
  .social-links {
    margin: 0 0.7rem;
  }
  
  .social-links h2 {
    font-size: 34px;
    color: #00a3e0; /* Blå färg för rubrik */
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .social-links p, .about-text p {
    font-size: 18px;
    color: #fff; /* Vit textfärg */
  }
  
  .icons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .icons a {
    margin: 0 15px;
    text-decoration: none;
    transition: transform 0.2s ease-in-out;
  }
  
  .icons a:hover {
    transform: scale(1.1); /* Lätt förstoring vid hover-effekt */
  }
  
  .icons a:first-child {
    color: #00a3e0; /* Blå färg för Twitter-ikon */
  }
  
  .icons a:last-child {
    color: #00a3e0; /* Blå färg för Telegram-ikon */
  }

  /*  */
  @media (min-width: 768px) {
    .social-icons {
      flex-direction: row; /* Placera ikonerna bredvid varandra vid större skärmbredder */
    }
  
    .social-icons li {
      margin-right: 20px; /* Skapa mellanrum mellan ikonerna */
    }

  }
  
  .footer {
    background-color: #000; /* Svart bakgrund */
    color: #fff; /* Vit textfärg */
    padding: 50px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .footer-logo {
    margin-bottom: 20px;
  }
  
  .footer-logo img {
    max-width: 50px; /* Justera storlek efter behov */
    height: auto;
  }
  
  .footer-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .footer-column {
    flex: 1;
    margin: 0 10px;
  }
  
  h4 {
    font-size: 20px;
    color: #00a3e0; /* Blå färg för rubrik */
    margin-bottom: 20px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  li {
    margin-bottom: 10px;
  }
  
  li a {
    font-size: 18px;
    color: #fff; /* Vit textfärg för länkar */
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  li a:hover {
    color: #00a3e0; /* Blå färg för länkar vid hover-effekt */
  }
  
  .social-icons {
    display: flex;
    flex-direction: column; /* Placera ikonerna ovanför varandra i mobilläge */
    align-items: center;
  }
  
  .social-icons i {
    font-size: 24px;
    margin-right: 5px;
  }
  
  .footer-bottom {
    margin-top: 40px;
    text-transform: uppercase; /* Gör texten i footer-bottom till versaler */
  }
  
  .footer-bottom p {
    font-size: 12px;
  }

  .hero-text button{
    cursor: pointer;
    font-size: 1.1rem;
  }

  /*  */
  /* Global CSS-stilar */
.darkset-development-page {
  font-family: Arial, sans-serif;
  background: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%);
  color: #ffffff; /* Vit textfärg */
}

/* Header-stilar */
.header {

  padding: 50px 0;
}

.header h1 {
  font-size: 36px;
}

/* Stilar för sektioner */
.section {
  padding: 40px 0;
  text-align: center;
}

.section h2 {
  font-size: 24px;
}

/* Footer-stilar */
.footer {
  text-align: center;
  padding: 20px 0;
}


/*  */
.community-container {
  position: relative;
  background: rgba(10, 10, 10, 0.98); 
  color: rgba(255, 255, 255, 0.9);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
 
}

.community-container:hover {
  background: rgba(15, 15, 15, 0.98);
}

.community-container h1 {
  font-size: 2.4rem;
  background: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%);
  -webkit-background-clip: text;
  color: transparent;
  max-width: 600px;
  line-height: 46px;
  font-family: 'Raleway', sans-serif;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.community-container h2 {
  width: 70%;
  line-height: 26px;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 1.5rem;
  opacity: 0.85;
}

.community-container h3 {
  font-size: 2rem;
  margin: 2rem 0;
  text-align: center;
  font-weight: 500;
  background: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%);
  -webkit-background-clip: text;
  color: transparent;
}

.community-container p {
  margin: 1rem 0;
  text-align: center;
  max-width: 600px;
  opacity: 0.75;
  transition: opacity 0.3s ease;
}

.community-container p:hover {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .community-container h1 {
      font-size: 2rem;
  }

  .community-container h2, .community-container p {
      width: 90%;
  }
}


.community-container img {
  width: 45%;  
  height: auto;
  display: block;
  margin: 40px auto 25px auto; /* Adjusted margin to remove the bottom padding effect */
  padding: 15px 15px 0 15px;  /* Removed bottom padding */
  border-radius: 50%; 
  overflow: hidden;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.7), 0 10px 40px rgba(0, 0, 0, 0.5);
  transform: scale(1.05) translateY(-5px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: none;  
  animation: planetOrbit 10s infinite; /* Added animation */
}

@keyframes planetOrbit {
  0% {
      transform: scale(1.05) translateY(-5px) translateX(0);
  }
  25% {
      transform: scale(1.05) translateY(-10px) translateX(10px);
  }
  50% {
      transform: scale(1.05) translateY(-5px) translateX(20px);
  }
  75% {
      transform: scale(1.05) translateY(-10px) translateX(10px);
  }
  100% {
      transform: scale(1.05) translateY(-5px) translateX(0);
  }
}

.community-container img:hover {
  transform: scale(1.08) translateY(-10px) translateX(10px);
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.8), 0 12px 45px rgba(0, 0, 0, 0.6);
}

/*  */


header {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;

}

.swap-swap-logo{
  width: 40px;
  height: 40px;
  padding-right: 20px;
}

.eth{
  width: 20px;
  height: 20px;
  padding-right: 10px;
  background-color: white;

}

.leftH {
 margin-right: 1.5rem;
}

.rightH {
  margin-left: 1.5rem;

}

.headerItem {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-weight: 500;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.headerItem:hover {
  background-color: rgb(34, 42, 58);
  cursor: pointer;
}

.connectButton {
  background-color: #243056; 
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 100px;
  color: #5981F3;
  font-weight: bold;
  transition: 0.3s;
}

.connectButton:hover {
  cursor: pointer;
  color: #3b4874
}

.mainWindow {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.link {
  text-decoration: none;
  color: white;
}

.tradeBox {
  width: 92%;
  background-color: #0E111B;
  border: 2px solid #21273a;
  min-height: 300px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
justify-content: center;
align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  margin: 1rem auto;
}

.tradeBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
}

.cog {
  color: #51586f;
  transition: 0.3s;
}

.cog:hover {
  color: white;
  rotate: 90deg;
  cursor: pointer;
}

.switchButton {
  background-color: #3a4157;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
  position: absolute;
  top: 86px;
  left: 180px;
  color: #5F6783;
  border: 3px solid #0E111B;
  font-size: 12px;
  transition: 0.3s
}

.dollarAmount {
  position: absolute;
  top: 75px;
  left: 14px;
  font-size: 10px;
  color: #5F6783;
}

.switchButton:hover {
  color: white;
  cursor: pointer;
}

.inputs {
  position: relative;
  margin: 0 auto;
}

.assetOne {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 36px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetTwo {
  position: absolute;
  min-width: 50px;
  height: 30px;
  background-color: #3a4157;
  top: 135px;
  right: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font-weight: bold;
  font-size: 17px;
  padding-right: 8px;
}

.assetOne:hover{
  cursor: pointer;
}

.assetTwo:hover{
  cursor: pointer;
}

.assetLogo {
  height: 22px;
  margin-left: 5px;
}

.swapButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #243056; 
  width: calc(100%);
  height: 55px;
  font-size: 20px;
  border-radius: 12px;
  color: #5981F3;
  font-weight: bold;
  transition: 0.3s;
  margin-bottom: 30px;
  margin-top: 8px;
  width: 100%;

}

.swapButton[disabled] {
  background-color: #243056;
  opacity: 0.4;
  color: #5982f39b;
}

.swapButton[disabled]:hover {
  cursor: not-allowed;
  background-color: #243056;
}

.swapButton:hover {
  cursor: pointer;
  background-color: #3b4874
}

.tokenLogo {
  height: 40px;
  width: 40px;
}

.modalContent {
  border-top: 1px solid #363e54;
  /* padding-top: 20px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.tokenChoice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tokenChoice:hover {
  cursor: pointer;
  background-color: #1f2639;
}

.tokenName {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}

.tokenTicker {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 300;
  color: #51596f;
}
.tradeBoxHeader h4{
  background: linear-gradient(57.75deg, #8f0194 14.44%, #2C63FF 85.65%);
  background-clip: border-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-size: 1.4rem;
  font-family: 'Raleway', sans-serif;
  margin-top: 0.5rem;
}
.tradeBoxContainerLO{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bottomnavbox{
  border-radius: 5px;
  background-color: transparent;
}



.comunnity-c-img{
  height: 200px;
  width: auto;
}